<template>
  <div class="w-100 mb-4">
    <Header />
    <Form @saved="loadData" />
  </div>
</template>

<script>
import Header from "../components/Header.vue";
import Form from "../components/Form.vue";
import { mapActions } from "vuex";

export default {
  components: { Header, Form },
  methods: {
    ...mapActions("indicators", ["ActionShow"]),
    async loadData(id) {
      await this.ActionShow({ id: id }).catch(() => {
        this.$notifications.addNotification({
          message: "indicador não encontrado.",
          type: "danger",
        });
        //this.$router.push({ name: "indicators_list" });
      });
    },
  },
  async created() {
    await this.loadData(this.$route.params.id);
  },
};
</script>

<style></style>
